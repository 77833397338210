import { useCommonStore } from '../common';
import { type MyLevel, myPageService } from '../mypage';
import { useUserAuthStore } from '../userAuth';
import { type LevelNotification } from './type';

interface LevelState {
  myLevel: MyLevel | null;
  isShowAlarmIds: string[];
  popupVisible: boolean;
  popupTargetLevel: number | null;
}

const MAX_STORE_ID_BUFFER = 50;

export const useLevelStore = defineStore('user-level', {
  state: (): LevelState => ({
    myLevel: null,
    isShowAlarmIds: [],
    popupVisible: false,
    popupTargetLevel: null,
  }),
  actions: {
    async initMyLevel() {
      const response = await myPageService.getMyLevel();
      this.myLevel = response.data.data.items;
    },

    showPopup(level: number) {
      this.popupVisible = true;
      this.popupTargetLevel = level;
    },
    hidePopup() {
      this.popupVisible = false;
      this.popupTargetLevel = null;
    },

    setIsShowAlarmIds(shownAlarmId: string) {
      // persist store인 만큼 로컬 저장소에 50개까지만 저장하도록 함
      const current = [...this.isShowAlarmIds];
      this.isShowAlarmIds = [shownAlarmId, ...current.slice(0, MAX_STORE_ID_BUFFER - 1)];
    },

    hasNotifications<T extends object>(params: T): params is T & { notifications: LevelNotification[] } {
      return 'notifications' in params;
    },

    handleNotification(notifications: LevelNotification[]) {
      if (!notifications) {
        return;
      }
      const userAuthStore = useUserAuthStore();
      const commonStore = useCommonStore();
      if (notifications.length > 0) {
        const toastNotifications = notifications.filter((notification) => notification.clickType === 'TOAST');
        if (toastNotifications.length > 0) {
          const { notiTitle, notiMsg, clickPath } = toastNotifications[0];
          commonStore.showToast({
            text: notiTitle,
            subText: notiMsg,
            icon: false,
            duration: 4000,
            clickPath: clickPath?.replace('{USER_ID}', userAuthStore.user?.userId),
          });
        }

        const popupNotifications = notifications.filter((notification) => notification.clickType === 'POPUP');
        if (popupNotifications.length > 0) {
          const target = popupNotifications[0];
          const { notiTitle } = target;
          if (!(notiTitle.includes('Lv') && notiTitle.includes('배지'))) {
            return;
          }
          const targetLevelMatch = notiTitle.match(/\d+/);

          this.showPopup(parseInt(targetLevelMatch[0]));
        }
      }
    },
  },
  getters: {},
});
